.under-construction {
    text-align: center;
    padding: 2rem;
  }
  
  .under-construction h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .under-construction p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  
  /* You can add additional styling for the under construction image */
  .under-construction img {
    max-width: 100%;
  }
  