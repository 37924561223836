.sponsors{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    border-radius: 45px;
	padding: 1rem 0;
}
.sponsors img{
    cursor: pointer;
    padding: .5rem;
    margin: 1rem 1rem 0rem;
}
::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.clients{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.clients img{
    cursor: pointer;
    padding: .5rem;
    margin: 1rem 1rem 0rem;
}

@media(max-width: 768px) {
    .sponsors{
        padding: 15px;
        margin: 15px 5px;
        font-size: 14px;
    }
    .sponsors img{
        position: relative;
        max-width: 100%;
    }
    .clients{
        display: none;
    }
}